@if ((onLine$ | async) !== true) {
    <div
        class="offline-alert text-center p-2"
        translate="NotificationMessages.Errors.Offline"
    ></div>
}

@if ((appIsReady$ | async) === false) {
    <div class="loader-standalone-wrapper">
        <div class="loader loader-standalone">
            <svg class="loader-spinner" viewBox="0 0 50 50">
                <circle class="loader-spinner-path" cx="25" cy="25" fill="none" r="20" stroke-width="5"></circle>
            </svg>
            @if (bootstrapError$ | async) {
                <div translate="Errors.UnknownServer"></div>
            }
        </div>
        <i class="fa"></i><i class="fac"></i>
    </div>
}

@if (appIsReady$ | async) {
    <div class="{{ layoutName }}-page">
        <router-outlet />
    </div>

    <div class="page-loader" [class.is-loading]="pageLoader.isLoading$ | async">
        <loader-spinner [model]="pageLoader" />
    </div>

    <page-anchors [anchors]="pageAnchors" />

    <div class="page-notifications">
        @for (notification of notifications; track notification; let index = $index) {
            <notification-message
                [notification]="notification"
                (dismissEvent)="removeNotification(index)"
            />
        }
    </div>
}
