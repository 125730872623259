import { Component, OnInit } from '@angular/core';
import {
	LanguageService,
	PageService,
	PageLoaderService,
	NotificationService,
	onLine$,
	AppLanguage,
	useDefaultDateLocale,
	AppBaseComponent,
	PreloaderService,
	TelemetryService,
	mapToFullNameString
} from '@aston/foundation';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { defineLocale, enGbLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { frLocale, IPageAnchor } from '@aston/foundation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, take, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { combineLatest, timer } from 'rxjs';

import { AuthenticationService } from './authentication-module/services';
import { AppStoreActions, AppStoreSelectors } from './root-store/app-store';
import * as RoutesDefinitions from './routes-definitions';
import { AppConstants } from './app.constants';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	providers: [
		TranslatePipe
	]
})
export class AppComponent extends AppBaseComponent implements OnInit {
	pageAnchors: IPageAnchor[] = [];

	appIsReady$ = this.store.select(AppStoreSelectors.selectIsReady);
	currentUser$ = this.store.select(AppStoreSelectors.selectCurrentUser);
	bootstrapError$ = this.store.select(AppStoreSelectors.selectBootstrapError);

	onLine$ = onLine$;

	constructor(
		authenticationService: AuthenticationService,
		translateService: TranslateService,
		private telemetryService: TelemetryService,
		public translatePipe: TranslatePipe,
		languageService: LanguageService,
		pageService: PageService,
		notificationService: NotificationService,
		pageLoaderService: PageLoaderService,
		private bsLocaleService: BsLocaleService,
		private preloader: PreloaderService,
		route: ActivatedRoute,
		public modal: NgbModal,
		router: Router,
		private store: Store) {
		super(translateService, languageService, pageService, notificationService, pageLoaderService, route, router);

		defineLocale(AppLanguage.FR, frLocale);
		defineLocale(AppLanguage.FR, enGbLocale);

		combineLatest([
			authenticationService.getIsAuthorized(),
			translateService.get('Preloader.WaitOrClose'),
		]).pipe(
			filter(([isAuth]) => isAuth),
			tap(([_, waitOrClose]) => {
				this.preloader.showTips([ waitOrClose ])
				this.preloader.closePreloaderWhenPreloadersFinished()
					.then(evt => this.telemetryService.event('[App] Preloader closed', evt, { duration: evt.elapsed  }))	
			}),
			takeUntil(this.destroySubscriptions$),
		).subscribe();

		languageService.language$.subscribe(current => {
			this.bsLocaleService.use(current);
			useDefaultDateLocale(current)
		});

		pageService.pageAnchors$.subscribe(anchors => {
			this.pageAnchors = anchors;
		});

		router.events.pipe(
			filter(event => event instanceof NavigationStart),
			filter(_ => this.modal.hasOpenModals())
		).subscribe(_ => {
			alert(this.translatePipe.transform('App.NavigationMessage'));
			this.modal.dismissAll();
		});

		router.events.pipe(
			filter(event => event instanceof NavigationEnd),
		).subscribe(_ => {
			pageService.resetAnchors();

			let url = window.location.pathname;

			if (!url.includes(RoutesDefinitions.AuthenticationRoutePath)) {
				const tenantId = authenticationService.getTenantId();

				// the tenant id is not a part of the route so we remove it from the stored one
				url = url.replace('/' + tenantId + '', '');
				this.store.dispatch(AppStoreActions.UpdateUserSessionRequest({userSession: {lastRoute: url}}));
			}
		});

		translateService
			.get('Filters.DateExpression')
			.pipe(take(1))
			.subscribe(keys => {
				AppConstants.updateDateExpressionWithTranslation(keys);
			});
	}

	ngOnInit() {
		super.ngOnInit();

		this.currentUser$.pipe(
			filter(Boolean),
			switchMap(user => this.translateService.stream('Home.Page.Welcome', { name: mapToFullNameString(user.fullName) })),
			tap(message => this.preloader.withPreClosingMessage(message)),
			take(1))
			.subscribe()

		timer(0, AppConstants.SECOND)
		.pipe(
			switchMap(_ => this.store.select(AppStoreSelectors.selectIsInactive)),
			filter(isInactive => !isInactive)
		)
		.subscribe(_ => {
			this.store.dispatch(AppStoreActions.UpdateInactivityTimer());
		});
	}
}
